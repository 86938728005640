 <template>
  <div>
    <CCard>
      <CCardHeader class="font-weight-bold"> Manage Roles </CCardHeader>
      <CCardBody>
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" class="text-left">Roles</th>
                <th scope="col" class="text-center">Permissions</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody class="text-center pt-5" v-if="isLoading">
              <tr>
                <td class="text-center" colspan="5">
                  <CSpinner color="primary" size="lg" />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <template v-for="role in roles">
                <tr
                  v-if="
                    role.id === 11 && $store.state.current_user.role_id == 11
                  "
                  :key="role.id"
                >
                  <td>{{ role.name }}</td>
                  <td class="text-center">
                    <template v-for="permission in role.permissions">
                      <CBadge
                        color="success"
                        :key="permission.id"
                        class="mx-1"
                        >{{ permission.name }}</CBadge
                      >
                    </template>
                  </td>
                  <td></td>
                </tr>
              </template>
              <template v-for="role in roles">
                <tr v-if="role.id !== 11" :key="role.id">
                  <td>{{ role.name }}</td>
                  <td class="text-center">
                    <template v-for="permission in role.permissions">
                      <CBadge
                        color="success"
                        :key="permission.id"
                        class="mx-1"
                        >{{ permission.name }}</CBadge
                      >
                    </template>
                  </td>
                  <td>
                    <CButtonGroup size="sm">
                      <CButton color="info" @click="editRole(role.id)">
                        Edit</CButton
                      >
                      <CButton
                        @click="deleteRole(deleteModal, role)"
                        color="danger"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </CCardBody>
    </CCard>
    <RoleDelete />
  </div>
</template>

<script>
import { mapState } from "vuex";

const RoleDelete = () => import("@/views/Role/RoleDelete");
export default {
  name: "Roles",
  components: {
    RoleDelete,
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("roles/getRoles");
  },
  computed: { 
    ...mapState({
      roles: (state) => state.roles.roles,
      isLoading: (state) => state.roles.isLoading,
      deleteModal: (state) => state.roles.deleteModal,
    }),
  },
  methods: {
    deleteRole(bool, role) {
      this.$store.commit("roles/DELETE_MODAL", { bool: bool, role: role });
    },
    editRole(role_id) {
      this.$store.dispatch("roles/editRole", {
        role_id: role_id,
      });
      this.$router.push({ name: "Edit Role" });
    },
  },
};
</script>